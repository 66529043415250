import React from "react";
import { Link } from 'react-router-dom';

import { APIBASE } from "../helpers/const";

const Login = () => {
	return (
		<div className="container m-auto h-screen flex justify-center items-center flex-col">
			<img
				src="/logo.png"
				alt="Logo"
				className="max-w-xs w-36"
			/>
			<br />
			<button
				className="rounded-full bg-white text-black p-3 border border-gray-400 hover:bg-gray-200 transition-colors duration-300 flex items-center justify-center w-10/12 md:w-1/3 sm:w-2/3"
				onClick={e => {
					window.location.href = `${APIBASE}/auth/microsoft`;
				}}
			>
				<img
					src="/microsoft.png"
					className="iconBtn"
					alt="iconlogin"
				/>
				&nbsp;Login con Microsoft
			</button>
			<br />

			<Link to="/loginwpass" className="rounded-full bg-white text-black p-3 border border-gray-400 hover:bg-gray-200 transition-colors duration-300 flex items-center justify-center w-10/12 md:w-1/3 sm:w-2/3">
				<button
					className="flex items-center justify-center"
					onClick={e => {
						// redirect with react
						// history.push("/loginwpass");
					}}
				>
					<img
						src="/logo.png"
						className="iconBtn"
						alt="iconlogin"
					/>
					&nbsp;Login con Usuario y Contraseña
				</button>
			</Link>
		</div>
	);
};

export default Login;
