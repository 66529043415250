import * as XLSX from 'xlsx';
import ValidarExtension from './validar-extension';

export const ReadExcel = async (file) => {
    const reader = new FileReader();
    const json = await new Promise((resolve, reject) => {
        try {
            if (!file) {
                return resolve(null);
            }
            const { name } = file;
            const extensiones = ['xls', 'xlsx'];
            const validExt = ValidarExtension({
                fileName: name,
                extensiones,
            });
            if (!validExt) {
                alert(
                    `El archivo ${name} no es un archivo de Excel válido. Seleccione un archivo con extensión: ${extensiones.join(
                        ', '
                    )}`
                );
                return resolve(null);
            }
            reader.onload = (e) => {
                if (e.target) {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    return resolve(json);
                }
            };
            reader.readAsArrayBuffer(file);
        } catch (error) {
            reject(null);
        }
    });
    return json;
};

export const ExportTableToExcel = (tableId, filename = 'table.xlsx') => {

    // Obtener la tabla HTML
    const table = document.getElementById(tableId);

    // Convertir la tabla en un objeto de hoja de cálculo de Excel
    const sheet = XLSX.utils.table_to_sheet(table);

    // Crear un libro de trabajo de Excel
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');

    // Descargar el archivo de Excel
    XLSX.writeFile(workbook, filename);

}