import axios from 'axios'
import { APIBASE } from '../const'

const GetRefreshToken = async () => {
    const url = `${APIBASE}/auth/microsoft/refreshtoken`;
    // with credentials
    try {
        await axios.get(url
            , {
                withCredentials: true,
            })
    } catch (error) {
        alert("REVISA EL ERROR GET DE LA CONSOLA (Allí esta el link)")
    }


}

export default GetRefreshToken