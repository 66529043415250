import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import NewOptions from '../../utils/ReactSelect';
import Swal from 'sweetalert2';
import { RemoveAccents, RemoveMultipleSpaces } from '../../utils/strings';
import PostSolicitudBodega from '../../helpers/apis/PostSolicitudBodega';
import FindUserByRut from '../../helpers/apis/FindUserByRut';
import { useHistory } from 'react-router-dom';
import GetJefeAreasEmails from '../../helpers/apis/GetJefeAreasEmails';
import GetUnidadesMedidas from '../../helpers/apis/GetUnidadesMedidas';

const otNVOptions = (cant = 1000, year = 2023) => {
    let options = [];
    const cantCant = cant.toString().length;
    for (let i = 1; i <= cant; i++) {
        i = i.toString().padStart(cantCant, "0");
        options.push({ label: i + "-" + year, value: i + "-" + year })
    }
    return options;
}
const currentYear = new Date().getFullYear();
const defaultOTOptions = [...otNVOptions(999, currentYear - 1), ...otNVOptions(999, currentYear)];
const defaultNVOptions = [...otNVOptions(1000, currentYear - 1), ...otNVOptions(1000, currentYear)];

const FormularioSolicitudInterna = ({
    dataAuth
}) => {
    const history = useHistory()

    const [correosJefesAreas, setCorreosJefesAreas] = useState(["ingenieroproyectos@vms.cl", "jefe.bodega@vms.cl", "cristian.acuna@vms.cl"])

    const [unidadesMedidas, setUnidadesMedidas] = useState([
        "ninguna",
        "kg",
        "g",
        "lb",
        "oz",
        "m3",
        "cm3",
        "L",
        "ml",
        "m",
        "cm",
        "in",
        "ft",
        "m2",
        "cm2",
        "in2",
        "ft2",
    ])
    const CorreoSolicitante = dataAuth?.mail || "";
    const RutSolicitante = dataAuth?.name || "";

    const [ccSolicitante, setCcSolicitante] = useState(900);
    const [esTecno, setEsTecno] = useState(null);
    const [esTecnoResp, setEsTecnoResp] = useState(null);
    const [esOTNV, setEsOTNV] = useState(false);
    const [esOT, setEsOT] = useState(false);
    const [solicitudEnviada, setSolicitudEnviada] = useState(false);
    const [selectedCorreoJefeArea, setSelectedCorreoJefeArea] = useState(null);
    const [obsGeneral, setObsGeneral] = useState("");
    const [selectedOTNV, setSelectedOTNV] = useState(null);
    const [items, setItems] = useState([{ desc: "", cant: 1, unidad: "" }]);

    const sendForm = () => {
        let error = false;
        let data = {
            correoJefeArea: selectedCorreoJefeArea,
        }
        if (!selectedCorreoJefeArea
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Debe seleccionar un correo de jefe de área',
            });
            error = true;
        }
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (!item.desc) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe ingresar una descripción en el item ' + (i + 1),
                });
                error = true;
            }
            else if (!item.cant) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe ingresar una cantidad en el item ' + (i + 1),
                });
                error = true;
            }
            else if (!item.unidad) {
                item.unidad = "ninguna";
                setItems([...items]);
            }
        }

        if (esOTNV === true) {
            if (!selectedOTNV) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe seleccionar una OT o NV',
                });
                error = true;
            }
            else {
                data.isOT = esOT;
                data.otnv = selectedOTNV;
            }
        }
        else if (esOTNV === false) {
            data.isOT = false;
            data.otnv = "ninguna";
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Debe seleccionar si el equipamiento está asociado a una OT/NV o no',
            });
            error = true;
        }
        if (error) return;
        data.items = items;
        data.obsGeneral = obsGeneral;
        data.cc = ccSolicitante;
        //date in format yymmddhhmmss.ms (ms = milliseconds) UTC gmt-4
        const date = new Date();
        date.setHours(date.getHours() - 4);
        const ms = new Date().getMilliseconds().toString().padStart(3, "0");
        data.IdSolicitud = date.toISOString().replace(/[^0-9]/g, "").slice(2, 14) + "." + ms;
        data.date = date;
        data.CorreoSolicitante = CorreoSolicitante;
        data.RutSolicitante = RutSolicitante;
        // await msg
        Swal.fire({
            title: 'Enviando solicitud',
            text: 'Por favor espere...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        PostSolicitudBodega(data).then(resp => {
            Swal.fire({
                icon: 'success',
                title: 'Solicitud enviada',
                text: 'La solicitud ha sido enviada correctamente',
            });
            setSolicitudEnviada(true);
        }
        ).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error al enviar la solicitud',
            });
        })
    }

    useEffect(() => {
        // Buscando usuario...
        Swal.fire({
            title: 'Buscando usuario',
            text: 'Por favor espere...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        })
        FindUserByRut({ rut: RutSolicitante }).then(resp => {
            if (resp?.cc) {
                setCcSolicitante(resp.cc);
                Swal.close();
                // redirect to home with react
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ha ocurrido un error al buscar el usuario ¿Existe el rut en nómina sharepoint de rrhh?',
                })
                history.push('/');
            }
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error al buscar el usuario*',
            })
            history.push('/');
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        try {


            GetJefeAreasEmails().then(resp => {
                setCorreosJefesAreas(resp.data);
            }).catch(err => {
                alert("Ha ocurrido un error al buscar los correos de jefes de área");
                // go home
                window.location.href = "/";
            })
            GetUnidadesMedidas().then(resp => {
                setUnidadesMedidas(resp.data);
            }).catch(err => {
                alert("Ha ocurrido un error al buscar las unidades de medida");
                window.location.href = "/";
            }
            )
        } catch (error) {
            alert("Ha ocurrido un error al obtener data del servidor.");
            window.location.href = "/";
        }
    }, [])

    const [_nvInputValue, setNvInputValue] = useState('');
    const [nvOptions, setNvOptions] = useState([]);


    const loadOptions = inputValue => {
        return new Promise(resolve => {
            const newOptions = [...otNVOptions(9999, currentYear - 1), ...otNVOptions(9999, currentYear)].filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            ).slice(0, 100);
            setNvOptions(newOptions);
            resolve(newOptions);
        });
    };

    return (
        <div className='flex flex-col items-center justify-center min-h-screen'>
            <div className='flex flex-col'>
                <h1 className='font-bold text-5xl text-center text-cyan-800 mb-2 mt-8'>Formulario de solicitud interna (CC:{ccSolicitante})</h1>
                <hr className='mb-4' />
                {esTecnoResp === null && <>
                    <label className='text-cyan-900 text-xl'>¿Es tecnología?
                        <Select options={NewOptions("SI", "NO")} onChange={(e) => { setEsTecno(e.value === "SI") }} className='max-w-xl' />
                    </label>
                    <button disabled={esTecno === null} className='btn btn-primary max-w-fit mt-4' onClick={e => {
                        if (esTecno) {
                            setEsTecnoResp(true)
                        } else {
                            setEsTecnoResp(false)
                        }
                    }}>Enviar</button>
                </>}
                {esTecnoResp === true && <>
                    <p>
                        Para este tipo de equipamiento debe solicitar a un jefe de área que realice la solicitud mediante el siguiente formulario (Solo habilitado para jefes de área):
                        <br />
                        <a className='link link-primary' href='https://forms.office.com/r/L51r7x2Urr' target="_blank" rel="noreferrer">https://forms.office.com/r/L51r7x2Urr</a>
                        <br />
                        <button className='btn btn-primary max-w-fit mt-4' onClick={e => {
                            history.push('/');
                        }}>Volver</button>
                    </p>
                </>}
                {
                    esTecnoResp === false &&
                    <>

                        <label className='text-cyan-900 text-xl mr-5'>
                            Correo del jefe de área:
                            <Select options={NewOptions(...correosJefesAreas)} className='max-w-xl' onChange={
                                e => {
                                    setSelectedCorreoJefeArea(e.value)
                                }
                            } />
                        </label>

                        {/* Table inputs*/}

                        <hr className='my-3' />
                        <label className='text-cyan-900 text-xl'>
                            ¿Asociado a una OT o NV?
                            <Select options={NewOptions("SI", "NO")} className='max-w-xl'
                                defaultValue={{ label: "NO", value: "NO" }}
                                onChange={e => {
                                    setSelectedOTNV(null);
                                    setEsOTNV(e.value === "SI")
                                }} />
                        </label>
                        {esOTNV && <>
                            <div className='text-cyan-900 text-xl flex items-center mb-3 mt-5'>
                                N/V
                                &nbsp;
                                <input type="checkbox" className="toggle toggle-info" onChange={e => {
                                    setEsOT(e.target.checked)
                                    setSelectedOTNV(null)
                                }} />
                                &nbsp;
                                O/T:&nbsp;
                            </div>
                            {
                                esOT ?
                                    <Select
                                        className='max-w-xl'
                                        key={"otnv_select1"}
                                        value={selectedOTNV ? {
                                            label: selectedOTNV,
                                            value: selectedOTNV
                                        } : ""}
                                        onChange={e => {
                                            setSelectedOTNV(e.value)
                                        }}
                                        options={defaultOTOptions}
                                    />
                                    :
                                    <AsyncSelect
                                        className='max-w-xl'
                                        key={"otnv_select1"}
                                        value={selectedOTNV ? {
                                            label: selectedOTNV,
                                            value: selectedOTNV
                                        } : ""}
                                        onChange={e => {
                                            setSelectedOTNV(e.value)
                                        }}
                                        options={nvOptions}
                                        defaultOptions={defaultNVOptions}
                                        onInputChange={inputValue => setNvInputValue(inputValue)}
                                        loadOptions={loadOptions}
                                    />
                            }
                        </>}
                        <hr className='my-3' />
                        {
                            items.map((item, index) => {
                                return (
                                    <div className='flex items-center justify-center' key={index}>
                                        <label className='mt-4 font-bold'>
                                            {index + 1}:
                                        </label>
                                        <label className='text-cyan-900 text-xl'>Cantidad
                                            <textarea value={item.cant} onChange={e => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                                items[index].cant = Number(e.target.value)
                                                setItems([...items])
                                            }
                                            } className='text-md w-full p-1 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
                                            />
                                        </label>
                                        <label className='text-cyan-900 text-xl'>Descripción
                                            <textarea value={item.desc} onChange={e => {
                                                items[index].desc = RemoveMultipleSpaces(RemoveAccents(e.target.value)).toUpperCase().replace("|", "");
                                                setItems([...items])
                                            }
                                            } className='text-md w-full p-1 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500' />
                                        </label>


                                        <label className='text-cyan-900 text-xl'>Unidad
                                            <Select options={NewOptions(...unidadesMedidas)} onChange={(e) => {
                                                items[index].unidad = e.value
                                                setItems([...items])
                                            }} className='max-w-xl'

                                                defaultValue={NewOptions("ninguna")[0]}
                                            />
                                        </label>
                                        <button className='btn btn-error max-w-fit mt-4 ml-1 font-bold text-xl text-white rounded-xl hover:bg-red-500' onClick={() => {
                                            items.splice(index, 1)
                                            setItems([...items])
                                        }
                                        }>x</button>
                                    </div>
                                )
                            })
                        }

                        <br />
                        <button className='btn btn-accent max-w-fit' onClick={() => {
                            setItems([...items, { desc: "", cant: 1, unidad: "" }])
                        }}>Agregar item</button>
                        <hr className='my-3' />

                        <label className='text-cyan-900 text-xl'>Observación general (opcional):
                            <textarea
                                value={obsGeneral}
                                onChange={e => {
                                    setObsGeneral(RemoveMultipleSpaces(e.target.value));
                                }}
                                className='text-md w-full p-1 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500' />
                        </label>
                        <hr className='my-3' />

                        <button className='btn btn-primary mb-5' disabled={items.length === 0 || solicitudEnviada} onClick={sendForm}>Enviar solicitud</button>
                    </>
                }
            </div>
        </div>
    )
}

export default FormularioSolicitudInterna