import React from "react";
import { Route } from "react-router-dom";
import { APIBASE } from "./const";
import { ValidarRut } from "../utils/rut";
import Swal from "sweetalert2";
import FindLinks from "./apis/FindLink";

const checkAuth = async () => {
	const checkAuthURL = `${APIBASE}/auth/check`;
	const resp = await fetch(checkAuthURL, {
		credentials: "include"
	});
	const data = await resp.json();
	return data;
};

const logoutAuth = async () => {
	const logAuthURL = `${APIBASE}/auth/logout`;
	// please clear cookies
	document.cookie = "tokenvms=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	fetch(logAuthURL, {
		credentials: "include"
	})
		.then(data => data.json())
		.then(data => {
			window.location.href = "/login";
		})
		.catch(err => {
			window.location.href = "/login";
		});
};
const CheckRoles = (ValidRoles, dataRoles = "USER") => {
	dataRoles = dataRoles.split(",");
	dataRoles = dataRoles.map(role => role.trim().toUpperCase());
	return dataRoles.some(role => ValidRoles.includes(role));
}
/**
 * Example:
 * roles: "USER,TEC,ADMIN,MOD"
 * **/
const AuthRoute = ({ component: Component, roles: ROLES, ...rest }) => {
	if (!ROLES) ROLES = "USER,ADMIN";
	ROLES = ROLES.split(",").map(role => role.trim().toUpperCase());
	const [dataAuth, setDataAuth] = React.useState(null);
	const [linksData, setLinksData] = React.useState(null);

	React.useEffect(() => {
		(async () => {
			let data = checkAuth();
			let linksData = FindLinks();
			[data, linksData] = await Promise.all([data, linksData]);
			if (data) {
				if (data.isAuthenticated) {
					const validRole = CheckRoles(ROLES, data.role)
					if (!validRole) {
						window.location.href = "/404";
					}
				} else {
					window.location.href = "/login";
				}
			}

			if (!data || !linksData) {
				logoutAuth();
				window.location.href = "/login";
			}


			if (!ValidarRut(data.name)) {
				Swal.fire({
					title: "Información",
					text: "Rut no valido, favor solicitar configuración de rut.",
					icon: "info",
				}).then(() => {
					logoutAuth();
				}).catch(() => {
					logoutAuth();
				}
				);

			}
			setDataAuth(data);
			setLinksData(linksData.data);
		})();
	}, []);

	return (
		<>
			{dataAuth && dataAuth.isAuthenticated && CheckRoles(ROLES, dataAuth.role) && <Route {...rest} render={props =>
				<Component {...props} dataAuth={dataAuth} linksData={linksData} />} />}
			{!dataAuth && <h1>Loading...</h1>}
		</>
	);
};

/**
 * 
 * @param userRoles: ej: "USER,ADMIN,DEV" separados por comas
 * @param validRoles: ej: "USER,ADMIN" separados por comas
 * @returns 
 */
const AuthElement = ({ children, validRoles, userRoles = "" }) => {
	if (!validRoles) validRoles = "USER";
	validRoles = validRoles.trim().toUpperCase();
	const isValid = CheckRoles(validRoles, userRoles);
	return (
		<>
			{isValid && <>{children}</>}
		</>
	);
}

export default AuthRoute;
export { checkAuth, logoutAuth, CheckRoles, AuthElement };
