import axios from 'axios'
import { APIBASE } from '../const'

const FindUserByRutAlmuerzo = async ({ rut }) => {
    try {


        const url = `${APIBASE}/sp/list/BDAPPS/nomina_almuerzo_vms?rut=${rut}`
        // with credentials
        const resp = await axios.get(url
            , {
                withCredentials: true,
            })
        return resp.data
    } catch (error) {
        return null;
    }

}

export default FindUserByRutAlmuerzo