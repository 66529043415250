import React from 'react'
import { useHistory } from 'react-router-dom'

const Regresar = () => {
    const history = useHistory()
    return (
        <button className='regresarBTN' onClick={() => {
            // if no go back, go to home
            history.length > 1 ? history.goBack() : history.push('/')
            
        }}>
            <img src="/volver.png" alt="volverico" />
        </button>
    )
}

export default Regresar