import axios from 'axios'
import { FormatoRut } from '../../utils/rut'
import { APIBASE } from '../const'

const CreateUserFetch = async ({ rut, pass, mail }) => {

    try {


        rut = FormatoRut(rut)
        const url = `${APIBASE}/auth/rut/create`
        const resp = await axios.post(url, {
            rut: rut,
            pass: pass,
            mail
        }, {
            withCredentials: true
        })
        return resp.data

    } catch (error) {

        return null;

    }
}

export default CreateUserFetch