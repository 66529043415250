import axios from 'axios'
import { APIBASE } from '../const'

const GetUnidadesMedidas = async () => {
    try {


        const url = `${APIBASE}/sp/list/BDAPPS/unidades_medidas`
        const resp = await axios.get(url, {
            withCredentials: true,
        })
        return resp.data

    } catch (error) {
        return null;
    }
}

export default GetUnidadesMedidas