import axios from 'axios'
import { APIBASE } from '../const'

const PostSolicitudBodega = async (data) => {
    try {
        const url = `${APIBASE}/sp/list/BDAPPS/solicitudes_bodega`
        const resp = await axios.post(url, data, {
            withCredentials: true,
        })
        return resp.data
    } catch (error) {
        return null
    }


}

export default PostSolicitudBodega