import axios from 'axios'
import { FormatoRut } from '../../utils/rut'
import { APIBASE } from '../const'

const SetUserWpass = async ({ rut, pass }) => {
    try {


        rut = FormatoRut(rut)
        const url = `${APIBASE}/auth/rut/updatepass`
        // credential: include

        const resp = await axios.put(url, {
            rut: rut,
            pass: pass
        }, {
            withCredentials: true
        })
        return resp.data
    } catch (error) {
        return null;
    }

}

export default SetUserWpass