import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

function HuellaDigital() {
    const signatureRef = useRef(null);

    const handleClear = () => {
        signatureRef.current.clear();
    };

    const handleSave = () => {
        const dataUrl = signatureRef.current.toDataURL();
    };

    return (
        <div>
            <SignatureCanvas ref={signatureRef} />
            <button onClick={handleClear}>Limpiar</button>
            <button onClick={handleSave}>Guardar</button>
        </div>
    );
}

export default HuellaDigital;
