import axios from "axios"
import { APIBASE } from "../const"

const FindLinks = async () => {
    try {
        const url = `${APIBASE}/sp/list/BDAPPS/links_plataforma_vms`
        const resp = await axios.get(url, {
            withCredentials: true,
        })
        return resp.data
    } catch (error) {
        return null
    }
}

export default FindLinks