import React from 'react'
import { ExportTableToExcel, ReadExcel } from '../../utils/excel'
import { ExcelDateToJSDate } from '../../utils/excelDate'
import { FormatoRut, ValidarRut } from '../../utils/rut'
import Matriz2Html from '../../components/Matriz2Html';
import { noAccentsNoMultipleSpace } from '../../utils/strings';


const LimpiadorAlmuerzo = () => {
    const [dataAlmuerzo, setDataAlmuerzo] = React.useState(null)
    const [dataCounterGroup, setDataCounterGroup] = React.useState(null)
    const [dataAlmuerzoFiltered, setDataAlmuerzoFiltered] = React.useState(null)
    const [dataMatrix, setDataMatrix] = React.useState(null)
    const [relacionEmpresas, setRelacionEmpresas] = React.useState([])
    const [duplicatedCheck, setDuplicatedCheck] = React.useState(false)

    const handdleExcel = async (e) => {
        setDuplicatedCheck(false)
        const file = e.target.files[0]
        const resp = await ReadExcel(file)
        let cleanResp = []
        let _relacionEmpresas = []
        if (resp) {
            resp.forEach((item) => {
                let observacion = ""
                const Email = noAccentsNoMultipleSpace(item['Correo electrónico2']);
                const RelacionEmpresa = noAccentsNoMultipleSpace(item['Relación con la empresa']);
                // Almuerzos
                let LunesAlmuerzo = noAccentsNoMultipleSpace(item['Lunes']);
                let MartesAlmuerzo = noAccentsNoMultipleSpace(item['Martes']);
                let MiercolesAlmuerzo = noAccentsNoMultipleSpace(item['Miércoles']);
                let JuevesAlmuerzo = noAccentsNoMultipleSpace(item['Jueves']);
                let ViernesAlmuerzo = noAccentsNoMultipleSpace(item['Viernes']);
                // Entradas
                let LunesEntrada = noAccentsNoMultipleSpace(item['Lunes2']);
                let MartesEntrada = noAccentsNoMultipleSpace(item['Martes2']);
                let MiercolesEntrada = noAccentsNoMultipleSpace(item['Miércoles2']);
                let JuevesEntrada = noAccentsNoMultipleSpace(item['Jueves2']);
                let ViernesEntrada = noAccentsNoMultipleSpace(item['Viernes2']);
                // Bebestibles
                let LunesBebestible = noAccentsNoMultipleSpace(item['Lunes3']);
                let MartesBebestible = noAccentsNoMultipleSpace(item['Martes3']);
                let MiercolesBebestible = noAccentsNoMultipleSpace(item['Miércoles3']);
                let JuevesBebestible = noAccentsNoMultipleSpace(item['Jueves3']);
                let ViernesBebestible = noAccentsNoMultipleSpace(item['Viernes3']);
                if (LunesAlmuerzo === "COLACIÓN") {
                    // all entradas and bebestibles are NINGUNA
                    LunesEntrada = "NINGUNA ENTRADA"
                    LunesBebestible = "NINGUN BEBESTIBLE"
                }
                if (MartesAlmuerzo === "COLACIÓN") {
                    MartesEntrada = "NINGUNA ENTRADA"
                    MartesBebestible = "NINGUN BEBESTIBLE"
                }
                if (MiercolesAlmuerzo === "COLACIÓN") {
                    MiercolesEntrada = "NINGUNA ENTRADA"
                    MiercolesBebestible = "NINGUN BEBESTIBLE"
                }
                if (JuevesAlmuerzo === "COLACIÓN") {
                    JuevesEntrada = "NINGUNA ENTRADA"
                    JuevesBebestible = "NINGUN BEBESTIBLE"
                }
                if (ViernesAlmuerzo === "COLACIÓN") {
                    ViernesEntrada = "NINGUNA ENTRADA"
                    ViernesBebestible = "NINGUN BEBESTIBLE"
                }
                if (LunesAlmuerzo === "NINGUNA") {
                    LunesAlmuerzo = "NINGUN ALMUERZO"
                }
                if (MartesAlmuerzo === "NINGUNA") {
                    MartesAlmuerzo = "NINGUN ALMUERZO"
                }
                if (MiercolesAlmuerzo === "NINGUNA") {
                    MiercolesAlmuerzo = "NINGUN ALMUERZO"
                }
                if (JuevesAlmuerzo === "NINGUNA") {
                    JuevesAlmuerzo = "NINGUN ALMUERZO"
                }
                if (ViernesAlmuerzo === "NINGUNA") {
                    ViernesAlmuerzo = "NINGUN ALMUERZO"
                }
                if (LunesEntrada === "NINGUNA") {
                    LunesEntrada = "NINGUNA ENTRADA"
                }
                if (MartesEntrada === "NINGUNA") {
                    MartesEntrada = "NINGUNA ENTRADA"
                }
                if (MiercolesEntrada === "NINGUNA") {
                    MiercolesEntrada = "NINGUNA ENTRADA"
                }
                if (JuevesEntrada === "NINGUNA") {
                    JuevesEntrada = "NINGUNA ENTRADA"
                }
                if (ViernesEntrada === "NINGUNA") {
                    ViernesEntrada = "NINGUNA ENTRADA"
                }
                if (LunesBebestible === "NINGUNA") {
                    LunesBebestible = "NINGUN BEBESTIBLE"
                }
                if (MartesBebestible === "NINGUNA") {
                    MartesBebestible = "NINGUN BEBESTIBLE"
                }
                if (MiercolesBebestible === "NINGUNA") {
                    MiercolesBebestible = "NINGUN BEBESTIBLE"
                }
                if (JuevesBebestible === "NINGUNA") {
                    JuevesBebestible = "NINGUN BEBESTIBLE"
                }
                if (ViernesBebestible === "NINGUNA") {
                    ViernesBebestible = "NINGUN BEBESTIBLE"
                }
                const Fecha = item['Hora de finalización'];
                const FechaCleanConvert = ExcelDateToJSDate(Fecha)
                // set fecha to dd-mm-yyyy
                const FechaClean = `${FechaCleanConvert.getDate()}-${FechaCleanConvert.getMonth() + 1}-${FechaCleanConvert.getFullYear()}`

                let RUT = noAccentsNoMultipleSpace(item['Rut sin punto y sin guión  (Ejemplo: si su rut es 12.345.678-9 escribir 12345678)']);
                RUT = FormatoRut(RUT);
                const EsRUTValido = ValidarRut(RUT);
                if (!EsRUTValido) {
                    observacion = "Revisar rut."
                }

                if (!_relacionEmpresas.includes(RelacionEmpresa)) {
                    _relacionEmpresas.push(RelacionEmpresa)
                }

                const DataClean = {
                    rut: RUT,
                    email: Email,
                    relacionEmpresa: RelacionEmpresa,
                    fecha: FechaClean,
                    observacion,
                    almuerzos: {
                        lunes: LunesAlmuerzo,
                        martes: MartesAlmuerzo,
                        miercoles: MiercolesAlmuerzo,
                        jueves: JuevesAlmuerzo,
                        viernes: ViernesAlmuerzo,
                    },
                    entradas: {
                        lunes: LunesEntrada,
                        martes: MartesEntrada,
                        miercoles: MiercolesEntrada,
                        jueves: JuevesEntrada,
                        viernes: ViernesEntrada,
                    },
                    bebestibles: {
                        lunes: LunesBebestible,
                        martes: MartesBebestible,
                        miercoles: MiercolesBebestible,
                        jueves: JuevesBebestible,
                        viernes: ViernesBebestible,
                    }
                }
                cleanResp.push(DataClean)
            })
            setRelacionEmpresas(_relacionEmpresas)
            setDataAlmuerzo(cleanResp)
            setDataAlmuerzoFiltered(cleanResp)
            setDataCounterGroup(DataCounterByRelacionEmpresaFn(cleanResp))
            //TODO: OBTENER MATRIX DIRECTAMENTE
            setDataMatrix(DataToMatrix(DataCounterByRelacionEmpresaFn(cleanResp), _relacionEmpresas))
        }
    }




    const DataCounterByRelacionEmpresaFn = (data) => {
        let dataCount = {
            lunes: {
            },
            martes: {
            },
            miercoles: {
            },
            jueves: {
            },
            viernes: {
            },
        }
        data.forEach((item) => {
            const relacionEmpresa = item.relacionEmpresa
            if (!dataCount.lunes[relacionEmpresa + "_LU"]) {
                dataCount.lunes[relacionEmpresa + "_LU"] = {
                    almuerzos: {},
                    entradas: {},
                    bebestibles: {},
                }
            }
            if (!dataCount.martes[relacionEmpresa + "_MA"]) {
                dataCount.martes[relacionEmpresa + "_MA"] = {
                    almuerzos: {},
                    entradas: {},
                    bebestibles: {},
                }
            }
            if (!dataCount.miercoles[relacionEmpresa + "_MI"]) {
                dataCount.miercoles[relacionEmpresa + "_MI"] = {
                    almuerzos: {},
                    entradas: {},
                    bebestibles: {},
                }
            }
            if (!dataCount.jueves[relacionEmpresa + "_JU"]) {
                dataCount.jueves[relacionEmpresa + "_JU"] = {
                    almuerzos: {},
                    entradas: {},
                    bebestibles: {},
                }
            }
            if (!dataCount.viernes[relacionEmpresa + "_VI"]) {
                dataCount.viernes[relacionEmpresa + "_VI"] = {
                    almuerzos: {},
                    entradas: {},
                    bebestibles: {},
                }
            }
            // almuerzos
            dataCount.lunes[relacionEmpresa + "_LU"].almuerzos[item.almuerzos.lunes] = dataCount.lunes[relacionEmpresa + "_LU"].almuerzos[item.almuerzos.lunes] ? dataCount.lunes[relacionEmpresa + "_LU"].almuerzos[item.almuerzos.lunes] + 1 : 1
            dataCount.martes[relacionEmpresa + "_MA"].almuerzos[item.almuerzos.martes] = dataCount.martes[relacionEmpresa + "_MA"].almuerzos[item.almuerzos.martes] ? dataCount.martes[relacionEmpresa + "_MA"].almuerzos[item.almuerzos.martes] + 1 : 1
            dataCount.miercoles[relacionEmpresa + "_MI"].almuerzos[item.almuerzos.miercoles] = dataCount.miercoles[relacionEmpresa + "_MI"].almuerzos[item.almuerzos.miercoles] ? dataCount.miercoles[relacionEmpresa + "_MI"].almuerzos[item.almuerzos.miercoles] + 1 : 1
            dataCount.jueves[relacionEmpresa + "_JU"].almuerzos[item.almuerzos.jueves] = dataCount.jueves[relacionEmpresa + "_JU"].almuerzos[item.almuerzos.jueves] ? dataCount.jueves[relacionEmpresa + "_JU"].almuerzos[item.almuerzos.jueves] + 1 : 1
            dataCount.viernes[relacionEmpresa + "_VI"].almuerzos[item.almuerzos.viernes] = dataCount.viernes[relacionEmpresa + "_VI"].almuerzos[item.almuerzos.viernes] ? dataCount.viernes[relacionEmpresa + "_VI"].almuerzos[item.almuerzos.viernes] + 1 : 1
            // entradas
            dataCount.lunes[relacionEmpresa + "_LU"].entradas[item.entradas.lunes] = dataCount.lunes[relacionEmpresa + "_LU"].entradas[item.entradas.lunes] ? dataCount.lunes[relacionEmpresa + "_LU"].entradas[item.entradas.lunes] + 1 : 1
            dataCount.martes[relacionEmpresa + "_MA"].entradas[item.entradas.martes] = dataCount.martes[relacionEmpresa + "_MA"].entradas[item.entradas.martes] ? dataCount.martes[relacionEmpresa + "_MA"].entradas[item.entradas.martes] + 1 : 1
            dataCount.miercoles[relacionEmpresa + "_MI"].entradas[item.entradas.miercoles] = dataCount.miercoles[relacionEmpresa + "_MI"].entradas[item.entradas.miercoles] ? dataCount.miercoles[relacionEmpresa + "_MI"].entradas[item.entradas.miercoles] + 1 : 1
            dataCount.jueves[relacionEmpresa + "_JU"].entradas[item.entradas.jueves] = dataCount.jueves[relacionEmpresa + "_JU"].entradas[item.entradas.jueves] ? dataCount.jueves[relacionEmpresa + "_JU"].entradas[item.entradas.jueves] + 1 : 1
            dataCount.viernes[relacionEmpresa + "_VI"].entradas[item.entradas.viernes] = dataCount.viernes[relacionEmpresa + "_VI"].entradas[item.entradas.viernes] ? dataCount.viernes[relacionEmpresa + "_VI"].entradas[item.entradas.viernes] + 1 : 1
            // bebestibles
            dataCount.lunes[relacionEmpresa + "_LU"].bebestibles[item.bebestibles.lunes] = dataCount.lunes[relacionEmpresa + "_LU"].bebestibles[item.bebestibles.lunes] ? dataCount.lunes[relacionEmpresa + "_LU"].bebestibles[item.bebestibles.lunes] + 1 : 1
            dataCount.martes[relacionEmpresa + "_MA"].bebestibles[item.bebestibles.martes] = dataCount.martes[relacionEmpresa + "_MA"].bebestibles[item.bebestibles.martes] ? dataCount.martes[relacionEmpresa + "_MA"].bebestibles[item.bebestibles.martes] + 1 : 1
            dataCount.miercoles[relacionEmpresa + "_MI"].bebestibles[item.bebestibles.miercoles] = dataCount.miercoles[relacionEmpresa + "_MI"].bebestibles[item.bebestibles.miercoles] ? dataCount.miercoles[relacionEmpresa + "_MI"].bebestibles[item.bebestibles.miercoles] + 1 : 1
            dataCount.jueves[relacionEmpresa + "_JU"].bebestibles[item.bebestibles.jueves] = dataCount.jueves[relacionEmpresa + "_JU"].bebestibles[item.bebestibles.jueves] ? dataCount.jueves[relacionEmpresa + "_JU"].bebestibles[item.bebestibles.jueves] + 1 : 1
            dataCount.viernes[relacionEmpresa + "_VI"].bebestibles[item.bebestibles.viernes] = dataCount.viernes[relacionEmpresa + "_VI"].bebestibles[item.bebestibles.viernes] ? dataCount.viernes[relacionEmpresa + "_VI"].bebestibles[item.bebestibles.viernes] + 1 : 1
        })
        return dataCount
    }

    const DataToMatrix = (data, relacionEmpresas) => {
        // console.log(data)
        let matrix = [[
            ""
        ], ["PEDIDOS"]]
        const fillMatrix = (dataDay, dayName) => {
            Object.entries(dataDay).forEach(([keyZo, valueZo], idxZona) => {

                matrix[0].push(dayName)
                const zona = keyZo;
                matrix[1].push(zona)
                const almuerzos = valueZo.almuerzos;
                const entradas = valueZo.entradas;
                const bebestibles = valueZo.bebestibles;
                const fillInMatrix = (tipoAlimento) => {
                    Object.entries(tipoAlimento).forEach(([keyAl, valueAl]) => {
                        // add in first column new row with key value if not exist
                        if (!matrix.find((item) => item[0] === keyAl)) {
                            matrix.push([keyAl])
                            const diasDeLasemana = 5;
                            for (let i = 0; i < relacionEmpresas.length * diasDeLasemana; i++) {
                                matrix[matrix.length - 1].push(0)
                            }
                        }
                        // find idx of keyAl in matrix
                        const alRow = matrix.findIndex((item) => item[0] === keyAl)
                        const zoCol = matrix[1].findIndex((zo) => zo === keyZo)
                        matrix[alRow][zoCol] += valueAl

                    })
                }
                fillInMatrix(almuerzos);
                fillInMatrix(entradas);
                fillInMatrix(bebestibles);
            })
        }
        // lunes
        fillMatrix(data.lunes, "LUNES")
        // martes
        fillMatrix(data.martes, "MARTES")
        // miercoles
        fillMatrix(data.miercoles, "MIERCOLES")
        // jueves
        fillMatrix(data.jueves, "JUEVES")
        // viernes
        fillMatrix(data.viernes, "VIERNES")
        return matrix

    }



    const RemoveDuplicatedRut = (e) => {

        if (e === true || e.target.checked) {
            // filter no duplicated by rut
            const dataClean = dataAlmuerzo.filter((item, idx, self) =>
                idx === self.findIndex((t) => (
                    t.rut === item.rut
                ))
            )

            setDataAlmuerzoFiltered(dataClean)
            setDataCounterGroup(DataCounterByRelacionEmpresaFn(dataClean))
            setDataMatrix(DataToMatrix(DataCounterByRelacionEmpresaFn(dataClean), relacionEmpresas))
        } else {
            setDataAlmuerzoFiltered(dataAlmuerzo)
            setDataCounterGroup(DataCounterByRelacionEmpresaFn(dataAlmuerzo))
            setDataMatrix(DataToMatrix(DataCounterByRelacionEmpresaFn(dataAlmuerzo), relacionEmpresas))
        }
    }


    return (
        <div className='containerVMS'>
            <br />
            <input type="file" name="file" id="file" className="input-file" onChange={handdleExcel} />


            {
                dataAlmuerzoFiltered && <>
                    <br />
                    <br />
                    <h2 className='text-3xl'>TABLA RESUMEN</h2>
                    <hr />
                    <br />
                    {/* checkboxes with options */}
                    <label className='text-xl flex items-center'>
                        Quitar Ruts duplicados&nbsp;
                        <input type="checkbox" name="duplicados"
                            checked={duplicatedCheck}
                            className="checkbox checkbox-accent"
                            onChange={(e) => {
                                setDuplicatedCheck(e.target.checked)
                                RemoveDuplicatedRut(e)
                            }} />
                    </label>
                    <br />

                </>
            }
            {dataAlmuerzoFiltered && <div className='tablePersonal'>

                <table className="table table-zebra w-full text-center wrapTextTable" id="TablaAlmuerzos">
                    <thead>
                        <tr>
                            <th className='bg-transparent'></th>
                            <th colSpan="4" className='borderborder-solid border-2 border-slate-500 bg-orange-400'>Info.</th>
                            <th colSpan="3" className='borderborder-solid border-2 border-slate-500 bg-yellow-400'>Lunes</th>
                            <th colSpan="3" className='borderborder-solid border-2 border-slate-500 bg-green-400'>Martes</th>
                            <th colSpan="3" className='borderborder-solid border-2 border-slate-500 bg-blue-400'>Miércoles</th>
                            <th colSpan="3" className='borderborder-solid border-2 border-slate-500 bg-indigo-400'>Jueves</th>
                            <th colSpan="3" className='borderborder-solid border-2 border-slate-500 bg-violet-400'>Viernes</th>
                        </tr>
                        <tr className='stickyTableVMS'>
                            <th className='borderborder-solid border-2 border-slate-500'>RUT</th>
                            <th className='borderborder-solid border-2 border-slate-500'>Email</th>
                            <th className='borderborder-solid border-2 border-slate-500'>Rel. VMS</th>
                            <th className='borderborder-solid border-2 border-slate-500'>Fecha<br />d-m-a</th>
                            <th className='borderborder-solid border-2 border-slate-500'>Observacion</th>
                            {
                                ["LU", "MA", "MI", "JU", "VI"].map((day, i) => (
                                    <React.Fragment key={i}>
                                        <th className='borderborder-solid border-2 border-slate-500'>Entrada_{day}</th>
                                        <th className='borderborder-solid border-2 border-slate-500'>Almuerzo_{day}</th>
                                        <th className='borderborder-solid border-2 border-slate-500'>Bebestible_{day}</th>
                                    </React.Fragment>
                                ))
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataAlmuerzoFiltered.map((item, i) => (
                                <tr key={i}>
                                    <td className='borderborder-solid border-2 border-slate-500 bg-emerald-200'>{item.rut}</td>
                                    <td className='borderborder-solid border-2 border-slate-500'>{item.email}</td>
                                    <td className='borderborder-solid border-2 border-slate-500'>{item.relacionEmpresa}</td>
                                    <td className='borderborder-solid border-2 border-slate-500'>{item.fecha}</td>
                                    <td className='borderborder-solid border-2 border-slate-500'>{item.observacion}</td>
                                    {
                                        [1, 2, 3, 4, 5].map((_, i) => (
                                            <React.Fragment key={i}>
                                                <td className='borderborder-solid border-2 border-slate-500'>{item.entradas[Object.keys(item.entradas)[i]]}</td>
                                                <td className='borderborder-solid border-2 border-slate-500'>{item.almuerzos[Object.keys(item.almuerzos)[i]]}</td>
                                                <td className='borderborder-solid border-2 border-slate-500'>{item.bebestibles[Object.keys(item.bebestibles)[i]]}</td>
                                            </React.Fragment>
                                        ))
                                    }
                                </tr>))
                        }

                    </tbody>
                </table>

            </div>}
            {
                dataAlmuerzoFiltered &&
                <p > Filas totales {dataAlmuerzoFiltered.length}</p>
            }
            {
                dataAlmuerzoFiltered &&
                <>

                    <br />
                    <button className='btn btn-primary ml-1' onClick={() => ExportTableToExcel(
                        'TablaAlmuerzos',
                        'TablaAlmuerzos.xlsx'
                    )}>Exportar</button>
                    <br />
                    <br />

                </>
            }
            {dataAlmuerzoFiltered && <>
                <br />
                <div className='tablePersonal'>
                    <Matriz2Html data={dataMatrix} id="MatrizResumen" />
                </div>
                <br />
                <button className='btn btn-accent ml-1' onClick={() => ExportTableToExcel(
                    'MatrizResumen',
                    'MatrizResumenAlmuerzo.xlsx'
                )}>Exportar Matriz</button>
                <br />
                <br />
            </>}

            <br />
            <br />
        </div>
    )
}

export default LimpiadorAlmuerzo