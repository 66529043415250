import React from 'react';

function Matriz2Html(props) {
    let { data, id } = props;
    if(!id) id = Math.floor(Math.random() * 1000000);
    return (
        <table className="table table-zebra w-full text-center wrapTextTable matrizAlmuerzoTabla" id={id}>
            <thead>
                <tr>
                    <th>Matriz</th>
                    {/* {data[0].map((dato, index) => (
                        <th key={index}>{`Columna ${index + 1}`}</th>
                    ))} */}
                </tr>
            </thead>
            <tbody>
                {data.map((fila, index) => (
                    <tr key={index}>
                        {/* <th>{`Fila ${index + 1}`}</th> */}
                        {fila.map((dato, index) => (
                            <td key={index} className='borderborder-solid border-2 border-slate-500'>{dato}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Matriz2Html;