import axios from 'axios'
import { APIBASE } from '../const'

const GetJefeAreasEmails = async () => {
    try {
        const url = `${APIBASE}/sp/list/BDAPPS/email_jefe_areas`
        const resp = await axios.get(url, {
            withCredentials: true,
        })
        return resp.data
    } catch (error) {
        return null
    }
}

export default GetJefeAreasEmails