import React from "react";
import { Link } from "react-router-dom";
import { AuthElement, logoutAuth } from "../helpers/auth";
import GetRefreshToken from "../helpers/apis/GetRefreshToken";


const Home = ({ dataAuth, linksData }) => {
	const { URL_role_plataforma_vms, URL_getTokenAzure } = linksData;
	return (
		<div className="container m-auto h-screen flex justify-center items-center flex-col">
			<img src="/logo.png" alt="Logo" className="max-w-xs w-36" />

			<div>
				<p>Rut: {dataAuth.name}</p>
				<p>Mail: {dataAuth.mail}</p>
				<p>Rol: {dataAuth.role}</p>
			</div>
			<br />
			<div className="w-screen h-px bg-slate-400" />
			<br />
			<a className="btn btn-circle btn-primary border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" href="/rrhh/ingresoalmuerzo" target="_blank" rel="noreferrer">
				Ingreso Almuerzo
			</a>
			<br />


			<AuthElement validRoles="ADMIN,RRHH" userRoles={dataAuth.role}>
				<Link className="btn btn-circle bg-yellow-500 hover:bg-yellow-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" to="/rrhh/configurar_sol_almuerzo">
					Configurar solicitud almuerzo
				</Link>
				<br />
			</AuthElement>
			<AuthElement validRoles="BODEGA" userRoles={dataAuth.role}>
				<Link className="btn btn-circle bg-amber-500 hover:bg-amber-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" to="/bodega/configurar_sistema_sol_interna">
					Configurar sistema solicitud interna
				</Link>
				<br />
			</AuthElement>
			<AuthElement validRoles="ADMIN" userRoles={dataAuth.role}>
				<a className="btn btn-circle btn-accent border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" href={URL_role_plataforma_vms} target="_blank" rel="noreferrer">
					Panel de control Sharepoint Users [Requiere login office ADMIN]
				</a>
				<br />
			</AuthElement>
			<AuthElement validRoles="ADMIN,CREARUSUARIOS" userRoles={dataAuth.role}>
				<Link className="btn btn-circle bg-violet-500 hover:bg-violet-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" to="/adm/createuser">
					Crear Usuario con rol USER
				</Link>
				<br />
			</AuthElement>
			<AuthElement validRoles="ADMIN,CREARUSUARIOS" userRoles={dataAuth.role}>
				<Link className="btn btn-circle bg-orange-500 hover:bg-orange-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" to="/adm/setpass">
					Establecer o modificar contraseñas
				</Link>
				<br />
			</AuthElement>

			{/* <AuthElement validRoles="ADMIN" userRoles={dataAuth.role}>
				<Link className="btn btn-circle bg-yellow-500 hover:bg-yellow-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" href="/bodega/ingreso" rel="noreferrer">
					Ingreso inventario
				</Link>
				<br />
			</AuthElement> */}
			{/* Establecer fecha solicitud almuerzo */}

			<AuthElement validRoles="USER" userRoles={dataAuth.role}>
				<a className="btn btn-circle bg-cyan-500 hover:bg-cyan-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" href="/bodega/ingreso_sol_interna" rel="noreferrer" target="_blank">
					Solicitud de insumo
				</a>
				<br />
			</AuthElement>
			<AuthElement validRoles="ADMIN" userRoles={dataAuth.role}>
				{/* get refresh token */}
				<button onClick={GetRefreshToken} className="btn btn-circle bg-blue-800 hover:bg-blue-900 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3">
					Obtener Refresh token (OneDrive) [Requiere login office ADMIN]
				</button>
				<br />
			</AuthElement>
			<AuthElement validRoles="ADMIN" userRoles={dataAuth.role}>
				{/* get refresh token */}
				<a className="btn btn-circle bg-sky-800 hover:bg-sky-900 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3" href={URL_getTokenAzure} rel="noreferrer" target="_blank">
					Obtener Token (Office Azure) [Requiere login office ADMIN]
				</a>
				<br />
			</AuthElement>
			<button
				className="btn btn-circle bg-red-500 hover:bg-red-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3"
				onClick={e => {
					logoutAuth();
					e.target.disabled = true;
				}}
			>
				Salir
			</button>
			<br />
		</div>
	);
};

export default Home;
