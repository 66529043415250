import axios from "axios"
import { APIBASE } from "../const"

const GetLimitDateAlmuerzo = async () => {
    try {


        const url = `${APIBASE}/sp/list/BDAPPS/fecha_formulario_almuerzo`
        // with credentials
        const resp = await axios.get(url
            , {
                withCredentials: true,
            })
        return resp.data.data[0]
    } catch (error) {
        return ""
    }
}

export default GetLimitDateAlmuerzo