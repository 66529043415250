import React from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { APIBASE } from "../helpers/const";
import { FormatoRut } from "../utils/rut";

const LoginWPass = () => {
    const [rutVal, setRutVal] = React.useState("");
    const loginFetch = async (e) => {
        e.preventDefault();
        const rut = document.getElementById("rut").value;
        const pass = document.getElementById("pass").value;
        Swal.fire({
            title: 'Iniciando Sesión',
            // no button
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        const resp = await fetch(`${APIBASE}/auth/rut/login?rut=${rut}&pass=${pass}`, { credentials: "include" })
        if (resp.status === 401 || resp.status === 400) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'RUT o Contraseña incorrectos',
            });
            return;
        }
        Swal.close();
        window.location.href = "/";
    };
    return (
        <div className="container m-auto h-screen flex justify-center items-center flex-col">
            <img
                src="/logo.png"
                alt="Logo"
                className="max-w-xs w-36"
            />
            <br />

            <form onSubmit={loginFetch}>
                <div className="flex justify-center items-center flex-col w-screen">
                    <label htmlFor="rut">RUT</label>
                    <input type="text" name="rut" id="rut" className="rounded-xl w-4/5 sm:w-2/3 md:w-1/3 text-2xl p-1" onChange={(e) => {
                        setRutVal(FormatoRut(e.target.value));
                    }} value={rutVal} />
                    <br />
                    <label htmlFor="pass">Contraseña</label>
                    <input type="password" name="pass" id="pass" className="rounded-xl w-4/5 sm:w-2/3 md:w-1/3 text-2xl p-1" />
                    <br />
                    <button
                        className="flex items-center justify-center rounded-full bg-sky-100 text-black p-2 border border-gray-400 hover:bg-sky-200 transition-colors duration-300 w-10/12 md:w-1/3 sm:w-2/3 font-bold"
                        onClick={e => {
                            // redirect with react
                            // history.push("/loginwpass");
                        }}
                    >
                        <img
                            src="/logo.png"
                            className="iconBtn"
                            alt="iconlogin"
                        />
                        &nbsp;INGRESAR
                    </button>

                </div>
            </form>


        </div>
    );
};

export default LoginWPass;
