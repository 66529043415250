import React from 'react'
import Regresar from '../../components/Regresar'

const IngresoInventario = () => {
    return (
        <>
            <Regresar />
            <div>IngresoInventario</div>
        </>
    )
}

export default IngresoInventario