import md5 from 'md5';
import React from 'react'
import { useState } from 'react';
import Swal from 'sweetalert2';
import FindUserByRutAlmuerzo from '../../helpers/apis/FindUserByRutAlmuerzo';
import { FormatoRut, ValidarRut } from "../../utils/rut"
import { useEffect } from 'react';
import moment from 'moment-timezone';
import GetLimitDateAlmuerzo from '../../helpers/apis/GetLimitDateAlmuerzo';

const FormularioAlmuerzo = () => {
  const [dataTrabajador, setDataTrabajador] = useState(null)
  const [rutSearch, setRutSearch] = useState('')
  const [rutVal, setRutVal] = useState('')
  const [isRutValid, setIsRutValid] = useState(false)
  const RemoveAutoComplete = (id) => {
    const divContainer = document.getElementById(id)

    //  set the autocomplete attribute off for the input field n items
    for (let i = 0; i < divContainer.childNodes.length; i++) {
      if (divContainer.childNodes[i].nodeName === 'INPUT') {
        divContainer.childNodes[i].setAttribute('autocomplete', 'off')
      }
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setRutSearch('')
    const isEspecialRut = !isNaN(rutVal) && rutVal < 99999
    let rut = String(document.getElementById('rutForm').value).trim()
    if (ValidarRut(FormatoRut(rut)) || isEspecialRut) {
      Swal.fire({
        title: 'Buscando RUT',
        // no button
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      FindUserByRutAlmuerzo({ rut }).then(resp => {
        Swal.close();
        const ok = resp.ok;
        if (ok && resp.data.length > 0) {
          setDataTrabajador(resp.data[0])
          setIsRutValid(true)
          setRutSearch(String(rut).trim().toLocaleUpperCase());

          return;
        } else {
          setIsRutValid(false)
          Swal.fire({
            icon: 'error',
            title: 'Nomina: ',
            text: 'RUT no encontrado en nomina de almuerzos',
          });
        }
      }).catch(err => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Nomina: ',
          text: 'Error al buscar RUT en nomina de almuerzos',
        });
      })
    }
    else {
      setIsRutValid(false)
      Swal.fire({
        icon: 'error',
        title: 'Nomina: ',
        text: 'RUT no válido',
      });
    }

  }
  const chileNowDate = Number(moment.tz('America/Santiago').format('X'))
  const [formLimitDate, setFormLimitDate] = useState('LOADING')
  useEffect(() => {
    GetLimitDateAlmuerzo().then(resp => {
      if (resp) {
        const chileTimeFormLimit = Number(moment.tz(resp, 'America/Santiago').subtract(3, 'hours').format('X'))
        setFormLimitDate(chileTimeFormLimit)
        return
      }
      if (resp === "") {
        setFormLimitDate(9999999999999999999999)
        return;
      }
      setFormLimitDate('ERROR')

    }).catch(err => {
      setFormLimitDate('ERROR')
    })
  }, [])
  return (
    <>

      {
        formLimitDate !== "LOADING" && (
          chileNowDate < formLimitDate
        ) &&
        <>
          <img src="/logo.png" alt="Logo" className="max-w-xs w-20 m-auto" />
          <div className='max-w-xl m-auto mb-2'>
            <form onSubmit={handleSubmit}>
              <label className='text-md font-bold text-center'>Ingresar rut o número asignado para generar ticket de almuerzo
                <input type="text" className='text-md w-full p-1 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500' id="rutForm" onChange={e => {
                  setRutSearch('');
                  setRutVal(FormatoRut(e.target.value));
                }} value={rutVal} />
              </label>
              <button className='w-full p-1 mt-2 text-white bg-indigo-500 rounded-lg text-sm font-bold'>Buscar y generar ticket de almuerzo</button>
            </form>
          </div>
          {rutSearch && isRutValid && dataTrabajador && <div className='flex justify-center'>
            <>

              {rutSearch && <div style={{
                height: '70vh', width: '100vw', maxWidth: '95vw', border: "1px solid black",
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)", borderRadius: "10px", backgroundColor: "#31A1CE"
              }} id="NOAUTOCOMPLETE">

                <iframe title="1" onLoad={() => {
                  RemoveAutoComplete('NOAUTOCOMPLETE')
                }} src={`https://docs.google.com/forms/d/e/1FAIpQLSfqCVRFhgaOM3CjUKJC0s3BSY96qIYcJd9QYRbs3tN691Womg/viewform?usp=pp_url&entry.1871658934=${rutSearch}&entry.1534788631=${md5("rut" + rutSearch)}&entry.361978997=${dataTrabajador.nombre}&embedded=true`} width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0" style={{ borderRadius: "10px" }} >Cargando…</iframe>
              </div>}

            </>
          </div>
          }
          {
            rutSearch && !isRutValid && <div className='flex justify-center'>
              <h1 className='text-2xl font-bold text-center'>RUT NO VALIDO O NO EXISTE EN NOMINA DE ALMUERZO</h1>
            </div>
          }
        </>

      }
      {
        formLimitDate !== "LOADING" && (
          chileNowDate > formLimitDate
        ) && (Swal.fire({
          icon: 'info',
          title: 'Ya no se aceptan respuestas: ',
          text: 'Fecha límite para generar ticket de almuerzo ha expirado',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,

        })) && <></>
      }
      {
        formLimitDate === "LOADING" && <>
          <div className='flex justify-center items-center h-screen'>
            <h1 className='text-2xl font-bold text-center'>Cargando...</h1>
          </div>
        </>
      }
      {
        formLimitDate === "ERROR" && <>
          <div className='flex justify-center'>
            <h1 className='text-2xl font-bold text-center'>No se ha establecido Fecha Respuestas Almuerzo</h1>
          </div>
        </>
      }
    </>
  )
}

export default FormularioAlmuerzo