import React from 'react'
import SetUserWpass from '../../helpers/apis/SetUserWPass'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { FormatoRut } from '../../utils/rut'

const Setpass = () => {
    const [rutFormat, setRutFormat] = React.useState('')
    const SetUser = async (e) => {
        e.preventDefault()
        const rut = e.target.rut.value
        const pass = e.target.pass.value
        if (!rut) {
            Swal.fire({
                title: 'Debe ingresar un RUT',
                icon: 'error',
            })
            return
        }
        if (!pass) {
            Swal.fire({
                title: 'Debe ingresar una contraseña',
                icon: 'error',
            })
            return
        }
        if (pass) {
            // la contraseña entre 5 y 12 caracteres
            if (pass.length < 5 || pass.length > 15) {
                Swal.fire({
                    title: 'La contraseña debe tener más de 5 caracteres y menos de 15 caracteres',
                    icon: 'error',
                })
                return
            }
        }
        Swal.fire({
            title: 'Espere por favor...',
            // nobutton
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        })
        let response = null;
        try {
            response = await SetUserWpass({
                rut: rut,
                pass
            })
        } catch (error) {
            response = {
                ok: false,
                msg: "Ha ocurrido un error."
            }
        }
        Swal.fire({
            title: response?.msg,
            icon: response?.ok ? 'success' : 'error',
        })
    }
    return (
        <>
            <div className='container m-auto flex justify-center items-center flex-col'>
                <br />
                <img src='/logo.png' alt='Logo' className='max-w-xs w-32' />
                <h1 className='text-2xl font-bold text-center text-cyan-900'>Establecer o modificar contraseña Usuario</h1>
                <br />
            </div>
            <form className="flex justify-center items-center flex-col" method='POST' onSubmit={
                SetUser
            }>
                <label htmlFor="rut">RUT (Debe existir en nomina trabajadores de RRHH)</label>

                <input type="text" name="rut" id="rut" className="rounded-xl w-4/5 sm:w-2/3 md:w-1/3 text-2xl p-1" autoComplete="new-password"

                    onChange={(e) => {
                        const rut = FormatoRut(e.target.value)
                        setRutFormat(rut)
                    }}
                    value={rutFormat}
                />
                <br />
                <label htmlFor="pass">Contraseña</label>
                <input type="password" name="pass" id="pass" className="rounded-xl w-4/5 sm:w-2/3 md:w-1/3 text-2xl p-1" autoComplete="new-password" />
                <br />
                <button
                    className="flex items-center justify-center rounded-full bg-sky-100 text-black p-2 border border-gray-400 hover:bg-sky-200 transition-colors duration-300 w-10/12 md:w-1/3 sm:w-2/3 font-bold"
                >
                    <img
                        src="/logo.png"
                        className="iconBtn"
                        alt="iconlogin"
                    />
                    &nbsp;Actualizar
                </button>
                <br />

                <Link className="btn btn-circle bg-amber-500 hover:bg-amber-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " to="/" >
                    Volver
                </Link>

            </form>
        </>
    )
}

export default Setpass