/* Esta funcion solo sirve para dar formato al rut de la forma que se trabajara en este proyecto
ej:
125556667 or 12.555.666-7 to 12555666-7
*/

export const FormatoRut = (rut) => {
	//removemos caracteres raros
	rut = String(rut)
	rut = rut.trim();
	rut = rut.replace(/[^0-9kK]/g, '');
	rut = rut.toLowerCase();
	rut = rut.replace('.', '');

	if (rut.indexOf('-') == -1 && rut.length > 7) {
		rut = rut.slice(0, -1) + '-' + rut.slice(-1);
	}
	return rut;
};

var Fn = {
	// Valida el rut con su cadena completa "XXXXXXXX-X"
	validaRut: function (rutCompleto) {
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
		var tmp = rutCompleto.split("-");
		var digv = tmp[1];
		var rut = tmp[0];
		if (digv == "K") digv = "k";
		return Fn.dv(rut) == digv;
	},
	dv: function (T) {
		var M = 0,
			S = 1;
		for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
		return S ? S - 1 : "k";
	},
};

export const ValidarRut = (rut) => {
	let rutValido = false;
	if (rut.length > 0) {
		rutValido = Fn.validaRut(FormatoRut(rut));
	}
	return rutValido;
};


