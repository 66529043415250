import React from 'react'
import CreateUserFetch from '../../helpers/apis/CreateUserFetch'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { FormatoRut } from '../../utils/rut'

const CreateUser = ({ linksData }) => {
    const URL_role_plataforma_vms = linksData.URL_role_plataforma_vms;
    const [rutFormat, setRutFormat] = React.useState('')
    const SetUser = async (e) => {
        e.preventDefault()
        const rut = e.target.rut.value
        const pass = e.target.pass.value
        const email = e.target.email.value
        if (!rut) {
            Swal.fire({
                title: 'Debe ingresar un RUT',
                icon: 'error',
            })
            return
        }
        if (!pass) {
            Swal.fire({
                title: 'Debe ingresar una contraseña',
                icon: 'error',
            })
            return
        }
        if (!email) {
            Swal.fire({
                title: 'Debe ingresar un email',
                icon: 'error',
            })
            return
        }

        if (pass) {
            // la contraseña entre 5 y 12 caracteres
            if (pass.length < 5 || pass.length > 15) {
                Swal.fire({
                    title: 'La contraseña debe tener más de 5 caracteres y menos de 15 caracteres',
                    icon: 'error',
                })
                return
            }
        }
        Swal.fire({
            title: 'Espere por favor...',
            // nobutton
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        })
        let response = null;
        try {
            response = await CreateUserFetch({
                rut: rut,
                pass,
                mail: email
            })
        }
        catch (error) {
            console.log(error)
        }
        Swal.fire({
            title: response?.msg,
            icon: response?.ok ? 'success' : 'error',
        })
    }
    return (
        <>
            <div className='container m-auto flex justify-center items-center flex-col'>
                <br />
                <img src='/logo.png' alt='Logo' className='max-w-xs w-32' />
                <h1 className='text-2xl font-bold text-center text-cyan-900'>Crear usuario con rol "USER"</h1>
                <br />
            </div>
            <form className="flex justify-center items-center flex-col" method='POST' onSubmit={
                SetUser
            }>
                <label htmlFor="rut">RUT (Debe existir en nomina trabajadores de RRHH)</label>

                <input type="text" name="rut" id="rut" className="rounded-xl w-4/5 sm:w-2/3 md:w-1/3 text-2xl p-1" autoComplete="new-password"
                    onChange={(e) => {
                        const rut = FormatoRut(e.target.value)
                        setRutFormat(rut)
                    }}
                    value={rutFormat}
                />
                <br />
                <label htmlFor="pass">Contraseña</label>
                <input type="password" name="pass" id="pass" className="rounded-xl w-4/5 sm:w-2/3 md:w-1/3 text-2xl p-1" autoComplete="new-password" />
                <br />
                {/* email */}
                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email" className="rounded-xl w-4/5 sm:w-2/3 md:w-1/3 text-2xl p-1" autoComplete="new-password" />
                <br />
                <button
                    className="flex items-center justify-center rounded-full bg-sky-100 text-black p-2 border border-gray-400 hover:bg-sky-200 transition-colors duration-300 w-10/12 md:w-1/3 sm:w-2/3 font-bold"
                >
                    <img
                        src="/logo.png"
                        className="iconBtn"
                        alt="iconlogin"
                    />
                    &nbsp;Crear Usuario
                </button>
                <br />
                <p className="text-center text-gray-500 text-xs">
                    El usuario se creará con el rol "USER" por defecto, para cambiar o agregar roles, debe hacerlo desde sharepoint en &nbsp;
                    <a href={URL_role_plataforma_vms} target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-700">Sharepoint role_plataforma_vms</a> [Requiere login office ADMIN].
                </p>
                <br />
                <Link className="btn btn-circle bg-amber-500 hover:bg-amber-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " to="/" >
                    Volver
                </Link>

            </form>
        </>
    )
}

export default CreateUser