import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AuthRoute from "./helpers/auth";
import { APIBASE } from "./helpers/const";
import IngresoInventario from "./views/bodega/IngresoInventario";
import Home from "./views/Home";
import LimpiadorAlmuerzo from "./views/rrhh/LimpiadorAlmuerzo";
import Login from "./views/Login";
import LoginWPass from "./views/LoginWPass";
import FormularioAlmuerzo from "./views/rrhh/FormularioAlmuerzo";
import Setpass from "./views/adm/Setpass";
import HuellaDigital from "./views/adm/HuellaDigital";
//@ts-ignore
import FormularioSolicitudInterna from "./views/bodega/FormularioSolicitudInterna.jsx";
import ConfigurarSolicitudAlmuerzos from "./views/rrhh/ConfigurarSolicitudAlmuerzos";
import CreateUser from "./views/adm/CreateUser";
import ConfigurarSistemaSolicitudInterna from "./views/bodega/ConfigurarSistemaSolicitudInterna";



function App() {

	const LogOut = () => {
		return (
			<>
				<h1>LogOut</h1>
				<br />
			</>
		);
	};

	const NotFound = () => {
		window.location.href = "/";
	};

	return (
		<>
			<div className='containerVMS' style={{ backgroundImage: `url(/FondoAgua.jpg) !important` }}>
				<Router>
					<Switch>
						<AuthRoute exact path="/" component={Home} />
						<Route path="/login" component={Login} />
						<Route path="/loginwpass" component={LoginWPass} />
						<Route path="/logout" component={LogOut} />
						
{/* Comentar la linea a continuación para limitar acceso de ingreso almuerzo y descomentar la linea que apunta al mismo link pero es tipo AuthRoute */}
						<Route path="/rrhh/ingresoalmuerzo" component={FormularioAlmuerzo} />
						{/* <AuthRoute roles="USER" path="/rrhh/ingresoalmuerzo" component={FormularioAlmuerzo} /> */}
{/*  */}

						<AuthRoute roles="USER" path="/bodega/ingreso_sol_interna" component={FormularioSolicitudInterna} />
						<AuthRoute roles="RRHH" path="/rrhh/configurar_sol_almuerzo" component={ConfigurarSolicitudAlmuerzos} />
						<AuthRoute roles="BODEGA" path="/bodega/configurar_sistema_sol_interna" component={ConfigurarSistemaSolicitudInterna} />
						<AuthRoute roles="ADMIN" path="/rrhh/almuerzo" component={LimpiadorAlmuerzo} />
						<AuthRoute roles="ADMIN" path="/bodega/ingreso" component={IngresoInventario} />
						<AuthRoute roles="ADMIN,CREARUSUARIOS" path="/adm/setpass" component={Setpass} />
						<AuthRoute roles="ADMIN,CREARUSUARIOS" path="/adm/createuser" component={CreateUser} />
						<AuthRoute roles="ADMIN" path="/adm/huelladigital" component={HuellaDigital} />
						<Route path="/404" component={NotFound} />
						<Redirect to="/404" />
					</Switch>
				</Router>
			</div>
			<img className="logoVMSPage" src="/logo.png" alt="logo" />
		</>
	);
}

export default App;
