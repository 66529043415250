import React from 'react'
import { Link } from 'react-router-dom'

const ConfigurarSistemaSolicitudInterna = ({ linksData }) => {
    // URL_email_jefe_areas, URL_solicitudes_bodega_detalle, URL_solicitudes_bodega_resumen, URL_unidades_medidas
    const { URL_email_jefe_areas, URL_solicitudes_bodega_detalle, URL_solicitudes_bodega_resumen, URL_unidades_medidas } = linksData
    return (
        <div className='container m-auto h-screen flex justify-center items-center flex-col'>
            <img src='/logo.png' alt='Logo' className='max-w-xs w-36' />
            <h1 className='text-2xl font-bold text-center text-cyan-900'>Configurar Sistema de solicitud interna [Requiere estar logueado en sharepoint]</h1>
            <br />
            <a className="btn btn-circle bg-orange-500 hover:bg-orange-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " href={URL_email_jefe_areas} target="_blank" rel="noreferrer">
                Configurar email jefes de área
            </a>
            <br />
            <a className="btn btn-circle bg-green-500 hover:bg-green-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " href={URL_unidades_medidas} target="_blank" rel="noreferrer">
                Configurar unidades de medida
            </a>
            <br />
            <a className="btn btn-circle bg-cyan-500 hover:bg-cyan-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " href={URL_solicitudes_bodega_resumen} target="_blank" rel="noreferrer">
                Ver resumen de solicitudes
            </a>
            <br />
            <a className="btn btn-circle bg-indigo-500 hover:bg-indigo-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " href={URL_solicitudes_bodega_detalle} target="_blank" rel="noreferrer">
                Ver detalle de solicitudes
            </a>
            <br />
            <Link className="btn btn-circle bg-amber-500 hover:bg-amber-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " to="/" >
                Volver
            </Link>
        </div>
    )
}

export default ConfigurarSistemaSolicitudInterna