export const RemoveAccents = (str) =>
	str
		.normalize('NFD')
		.replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
		.normalize();

export const RemoveMultipleSpaces = (str) => str.replace(/\s\s+/g, ' ');

export const RemoveAllSpaces = (str) => str.replace(/\s/g, '');

export const RemoveConectivesAndPrepositions = (str, lang = 'es') => {
	if (lang === 'en') {
		str = str.replace(/\b(and|the|of|to|in|for|with|on|at|by|from|up|about|into|over|after|beneath|under|within|along|following|across|behind|beyond|toward|towards|near|of|off|above|below|around|between|during|out|through|before|since|without|alongside|inside|outside|upon|down|off|out|up|with)\b/gi, '');
	} else if (lang === 'es') {
		str = str.replace(
			/\b(y|a|ante|bajo|cabe|con|contra|de|desde|durante|en|entre|hacia|hasta|mediante|para|por|según|sin|so|sobre|tras|versus|vía|el|la|los|las|un|una|unos|unas|lo|al|del|alguno|alguna|algunos|algunas|uno|una|unos|unas|algun|ningun|ninguna|ningunos|ningunas|cualquier|cualquiera|cualquieros|cualquieras|poco|poca|pocos|pocas|mucho|muchos|muchas|demasiado|demasiada|demasiados|demasiadas|tan|tanto|tanta|tantos|tantas|tanto|tanta|tantos|tantas|algun|ningun|cualquier|poco|mucho|demasiado|tan|tanto|tanta|tantos|tantas|tanto|tanta|tantos|tantas|el|la|los|las|un|una|unos|unas|lo|al|del|alguno|alguna|algunos|algunas|uno|una|unos|unas|algun|ningun|ninguna|ningunos|ningunas|cualquier|cualquiera|cualquieros|cualquieras|poco|poca|pocos|pocas|mucho|muchos|muchas|demasiado|demasiada|demasiados|demasiadas|tan|tanto|tanta|tantos|tantas|tanto|tanta|tantos|tantas|algun|ningun|cualquier|poco|mucho|demasiado|tan|tanto|tanta|tantos|tantas|tanto|tanta|tantos|tantas)\b/gi,
			''
		);
	}
	return str.replace(/\s\s+/g, ' ');
};

export const GetFirstLetters = (str) => {
	//str alphanumeric
	str = str.replace(/[\W_]+/g, ' ');
	let firstLetters = '';
	str.split(' ').forEach((word) => {
		if (word.length > 0) {
			firstLetters += word[0];
		}
	});
	return firstLetters.toUpperCase();
};

export const OnlyLetters = (str) => str.replace(/[^a-zA-Z ]/g, '');

export const OnlyNumbers = (str) => str.replace(/[^0-9]/g, '');

export const OnlyLettersAndNumbers = (str) => str.replace(/[^a-zA-Z0-9 ]/g, '');

export const isEmail = (str) => {
	const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	return String(str).match(validRegex);
};

export const isVeryLongText = (str, length = 200) => String(str).length > length;

export const noAccentsNoMultipleSpace = (str) => {
	str = String(str);
	str = str.trim();
	str = RemoveAccents(str);
	str = RemoveMultipleSpaces(str);
	str = str.toUpperCase();
	return str;
}