import React from 'react'
import { Link } from 'react-router-dom'

const ConfigurarSolicitudAlmuerzos = ({linksData}) => {
    const URL_archivosSistemaAlmuerzo = linksData.URL_archivosSistemaAlmuerzo;
    const URL_fecha_formulario_almuerzo = linksData.URL_fecha_formulario_almuerzo;
    const URL_nomina_almuerzo_vms = linksData.URL_nomina_almuerzo_vms;
    return (
        <div className='container m-auto h-screen flex justify-center items-center flex-col'>
            <img src='/logo.png' alt='Logo' className='max-w-xs w-36' />
            <h1 className='text-2xl font-bold text-center text-cyan-900'>Configurar Solicitud de Almuerzos [Requiere estar logueado en sharepoint]</h1>
            <br />
            <a className="btn btn-circle bg-orange-500 hover:bg-orange-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " href={URL_nomina_almuerzo_vms} target="_blank" rel="noreferrer">
                Configurar Nomina de almuerzos
            </a>
            <br />
            <a className="btn btn-circle bg-green-500 hover:bg-green-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " href={URL_fecha_formulario_almuerzo} target="_blank" rel="noreferrer">
                Configurar fecha de almuerzo
            </a>
            <br />
            <a className="btn btn-circle bg-cyan-500 hover:bg-cyan-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " href={URL_archivosSistemaAlmuerzo} target="_blank" rel="noreferrer">
                Ver archivos de sistema almuerzo
            </a>
            <br />
            <Link className="btn btn-circle bg-amber-500 hover:bg-amber-600 border-none shadow-xl max-w-full min-w-fit w-4/5 md:w-1/2 lg:w-1/3 " to="/" >
                Volver
            </Link>
        </div>
    )
}

export default ConfigurarSolicitudAlmuerzos